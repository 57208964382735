import { Box, Card, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import { Flex, Button, HStack, chakra } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useFirestoreDocDataOnce, useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';


function Header() {
    return (
        <chakra.header id="header">
            <Flex
                w="100%"
                px="6"
                py="5"
                align="center"
                justify="space-between"
            >
                <HStack></HStack>
                <HStack>
                    <Button>
                        Cerrar sesión
                    </Button>
                </HStack>

            </Flex>
        </chakra.header>
    );
}

function Account() {

    const [loading, setLoading] = useState(false)

    const toast = useToast()
    const currentAuth = getAuth()
    const ref = doc(getFirestore(), "users", currentAuth.currentUser?.uid)

    const functions = useFunctions();
    const generateSubscription = httpsCallable(functions, 'requestCustomerPortal');

    const { data } = useFirestoreDocDataOnce(ref)

    function validateName(value) {
        let error
        if (!value) {
            error = 'Name is required'
        }
        return error
    }
    return (
        <Box>
            <Header />
            <Stack style={{ padding: '10%' }}>
                <Text fontSize="2xl">Mi mano derecha</Text>
                {!data?.paid ? <Card align='center'>
                    <CardHeader>
                        <Heading size='md'>Nueva suscripción</Heading>
                    </CardHeader>
                    <CardBody>
                        <Text>Regístrate al plan para tener acceso a todo por US$9.99 al mes.</Text>
                    </CardBody>
                    <CardFooter>
                        <Button isLoading={loading} onClick={async () => {
                            try {
                                setLoading(true)
                                const remoteCalculatorResponse = await generateSubscription({ test: true, subscribe: true });
                                if (remoteCalculatorResponse.data.error) {
                                    toast({
                                        title: 'Error.',
                                        description: remoteCalculatorResponse.data.error.message,
                                        status: 'error',
                                        duration: 5000,
                                        isClosable: true,

                                    })
                                } else {
                                    //launch url
                                    window.open(remoteCalculatorResponse.data.url, '_blank')
                                }
                                console.log(remoteCalculatorResponse);
                                setLoading(false)
                            } catch (error) {
                                console.log(error)
                                setLoading(false)
                            }
                        }}>Suscribirme</Button>
                    </CardFooter>
                </Card> : <Card align='center'>
                    <CardHeader>
                        <Heading size='md'>Cambia tu suscripción</Heading>
                    </CardHeader>
                    <CardBody>
                        <Text>Ve a tu portal de cliente.</Text>
                    </CardBody>
                    <CardFooter>
                        <Button isLoading={loading} onClick={async () => {
                            try {
                                setLoading(true)
                                const remoteCalculatorResponse = await generateSubscription({ test: true, subscribe: false });
                                if (remoteCalculatorResponse.data.error) {
                                    toast({
                                        title: 'Error.',
                                        description: remoteCalculatorResponse.data.error.message,
                                        status: 'error',
                                        duration: 5000,
                                        isClosable: true,

                                    })
                                } else {
                                    //launch url
                                    window.open(remoteCalculatorResponse.data.url, '_blank')
                                }
                                console.log(remoteCalculatorResponse);
                                setLoading(false)
                            } catch (error) {
                                console.log(error)
                                setLoading(false)
                            }
                        }}  >Portal</Button>
                    </CardFooter>
                </Card>}
                <Text fontSize="2xl">Tu información</Text>
                <Formik
                    initialValues={data}
                    onSubmit={async (values, actions) => {
                        try {
                            await updateDoc(ref, {
                                ...values
                            })
                            actions.setSubmitting(false)
                            toast({
                                title: 'Actualizado.',
                                description: "Tu cuenta ha sido actualizada.",
                                status: 'success',
                                duration: 5000,
                                isClosable: true,
                            })
                        } catch (error) {
                            toast({
                                title: 'Error.',
                                description: error.message,
                                status: 'error',
                                duration: 5000,
                                isClosable: true,

                            })
                        }
                    }}
                >
                    {(props) => (
                        <Form>
                            <Field name='name' validate={validateName}>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.name && form.touched.name}>
                                        <FormLabel>Nombre</FormLabel>
                                        <Input {...field} placeholder='Nombre' />
                                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <div style={{ marginTop: '25px' }}></div>
                            <Field name='email' validate={validateName} >
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.name && form.touched.name}>
                                        <FormLabel>Correo electrónico</FormLabel>
                                        <Input {...field} placeholder='Email' />
                                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Button mt={4}
                                colorScheme='teal'
                                isLoading={props.isSubmitting}
                                type='submit' style={{ marginTop: '25px' }}>Guardar</Button>

                        </Form>
                    )}
                </Formik>




            </Stack>
        </Box>
    );
}

export default Account;