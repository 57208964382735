import './App.css';
import { AnalyticsProvider, AuthProvider, FirestoreProvider, FunctionsProvider, RemoteConfigProvider, StorageProvider, useFirebaseApp, useInitRemoteConfig } from 'reactfire';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
// import MainRoutes from './app/routes/MainRoutes';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import moment from 'moment';
import 'moment/locale/es-mx';

import MainRoutes from './app/MainRoutes';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { getFunctions } from 'firebase/functions';

const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
}

const theme = extendTheme({ colors })





function App() {
  moment.locale('es-MX')
  const app = useFirebaseApp();
  const firstore = getFirestore(app);
  const functions = getFunctions(app);
  //const remoteConf = getRemoteConfig(app)
  const storage = getStorage(app)
  const auth = getAuth(app);
  const analyticssdk = getAnalytics(app)

  // const appCheck = initializeAppCheck(app, {
  //   provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
  //   isTokenAutoRefreshEnabled: false,
  // });
  const { data: remoteConfigInstance } = useInitRemoteConfig(async (firebaseApp) => {
    const remoteConfig = getRemoteConfig(app);
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 10000,
      fetchTimeoutMillis: 10000,
    };
    try {
      await fetchAndActivate(remoteConfig);
      return remoteConfig;
    } catch (error) {
      console.log(error);
    }

  });







  return (

    // <AppCheckProvider sdk={appCheck}>
    <AuthProvider sdk={auth}>
      <FunctionsProvider sdk={functions}>
        <FirestoreProvider sdk={firstore}>
          <StorageProvider sdk={storage}>
            <RemoteConfigProvider sdk={remoteConfigInstance}>
              <AnalyticsProvider sdk={analyticssdk}>
                <ChakraProvider resetCSS={true} theme={theme}>
                  <div className="App">
                    <MainRoutes />
                  </div>
                </ChakraProvider>
              </AnalyticsProvider>
            </RemoteConfigProvider>
          </StorageProvider>
        </FirestoreProvider>
      </FunctionsProvider>

    </AuthProvider>
    // </AppCheckProvider >



  );
}

export default App;