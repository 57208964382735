import { Button, Stack, Text } from '@chakra-ui/react';
import { getAuth, signOut } from 'firebase/auth';
import React from 'react';

const Hero = () => {
    return (
        <section className="hero">
            <h2 className="hero-title">Tu nueva mano derecha</h2>
            <Stack>
                <Text className="hero-description">
                    Say goodbye to endless back-and-forth conversations and hello to productivity.

                </Text>
                <Text className="hero-description">
                    Starting at $9.99/month.
                </Text>
            </Stack>

            <Button className="" style={{
                marginTop
                    : '25px'
            }} onClick={() => {
                window.location.href = "/login"
            }}>Get Started</Button>
        </section>
    );
};

const LandingPage = ({ showLogout }) => {
    return (
        <div className="landing-page">
            {showLogout && <nav className="navbar">
                <div className="navbar-brand"></div>
                <button onClick={() => {
                    signOut(getAuth())
                }}>logout</button>
            </nav>}
            <Hero />
        </div>
    );
};

export default LandingPage;
