import React from 'react';
import { Routes, Route, } from "react-router-dom";
import LandingPage from './LandingPage';

import LoginPage from './Login';

function NotSignedRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<LandingPage type='login' showLogout={false} />} />
            <Route exact path="/login" element={<LoginPage type='login' />} />
        </Routes>
    );

}

export default NotSignedRoutes;