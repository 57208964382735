import React from 'react';
import { Routes, Route, } from "react-router-dom";
import { SuspenseWithPerf, useSigninCheck, useUser } from 'reactfire';
import Account from './Account';
import LandingPage from './LandingPage';
import NotSignedRoutes from './NotSignedRoutes';




/**
 * If the user is signed in, render the children, otherwise render the fallback
 * @returns The children or the fallback.
 */
export const AuthWrapper = ({ children, fallback }) => {
    const { data: signInCheckResult } = useSigninCheck();

    if (!children) {
        throw new Error('Children must be provided');
    }

    if (signInCheckResult.signedIn === true) {
        return children
    } else {
        return fallback;
    }
};



function MainRoutes() {
    const { data: authData } = useUser();







    return (

        // <Routes>
        //     <Route path="/:tab" element={<LoggedInLayout auth={authData} />} />
        //     <Route exact path="/" element={<LoggedInLayout auth={authData} />} />
        // </Routes>



        <SuspenseWithPerf traceId={'firebase-user-wait'} fallback={<>Loading</>}>
            <AuthWrapper fallback={<NotSignedRoutes />}>
                <Routes>

                    {/* //SET A DEFAULT PAGE */}
                    <Route path="/account" element={<Account auth={authData} showLogout={true} />} />
                    <Route path="*" element={<LandingPage auth={authData} showLogout={true} />} />
                </Routes>
            </AuthWrapper>
        </SuspenseWithPerf>



    );
}

export default MainRoutes;