import React, { useEffect, useState } from "react";
import { useAuth } from "reactfire";
import "firebase/auth";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Input, Box, Stack, Text, Button, Link } from '@chakra-ui/react'
import { doc, getDoc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import moment from "moment";


const LoginPage = () => {

    const [mynumber, setnumber] = useState("");
    const [otp, setotp] = useState('');
    const [show, setshow] = useState(false);
    const [final, setfinal] = useState('');
    const [rec, setRec] = useState(null)
    const [timer, setTimer] = useState(null)
    const [remainingTime, setRemainingTime] = useState(60)
    const auth = useAuth()


    useEffect(() => {
        if (!rec) {
            var appVerifier = new RecaptchaVerifier('recaptcha-container', {
                size: 'invisible',
            },
                auth);
            setRec(appVerifier);
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const signin = () => {

        var recVer = rec
        if (!recVer) {
            recVer = new RecaptchaVerifier('recaptcha-container', {
                size: 'invisible',
            },
                auth);
            setRec(recVer);
        }

        if (mynumber === "" || mynumber.length < 10) return;
        signInWithPhoneNumber(getAuth(), mynumber, recVer).then((result) => {
            setfinal(result);
            setshow(true);
            //create timer
            let time = 30;
            let timer = setInterval(() => {
                time--;
                console.log(time);
                setRemainingTime(time);
                if (time === 0) {
                    clearInterval(timer);
                }
            }, 1000)
            //run timer

            setTimer(timer)
        })
            .catch((err) => {
                console.log(err);
                alert(err);

            });
    }

    const ValidateOtp = () => {
        if (otp === null || final === null)
            return;
        final.confirm(otp).then(async (result) => {
            // success
            console.log(result);
            const ref = doc(getFirestore(), "users", result.user.uid)
            const current = await getDoc(ref);
            try {

                if (!current.exists()) {
                    await setDoc(ref, {
                        phone: result.user.phoneNumber,
                        displayName: result.user.displayName,
                        photoURL: result.user.photoURL,
                        uid: result.user.uid,
                        timestamp: moment().valueOf(),
                        lastLogin: moment().valueOf(),
                        paid: false
                    })
                } else {
                    await updateDoc(ref, {
                        lastLogin: moment().valueOf()
                    })

                }
                timer && clearInterval(timer);
                window.location.href = "/account"
            } catch (error) {
                console.log("🚀 ~ file: Login.js:97 ~ final.confirm ~ error", error)

            }


        }).catch((err) => {
            alert("Wrong code");
        })
    }



    return (
        <Box mx="auto" h="100vh" style={{ backgroundColor: '#f7f7f7', padding: '10%' }}>
            <Stack align="center">
                <Text fontSize="2xl">Mi mano derecha</Text>
                <div style={{ "marginTop": "80px" }}>
                    <center>
                        <div style={{ display: !show ? "block" : "none" }}>
                            <Text style={{ marginBottom: '10px' }}>Añade tu número de teléfono</Text>
                            <Input value={mynumber} onChange={(e) => {
                                setnumber(e.target.value)
                            }}
                                placeholder="Número de teléfono" />
                            <br /><br />
                            <div id="recaptcha-container"></div>
                            <Button onClick={signin}>Enviar código</Button>
                        </div>
                        <div style={{ display: show ? "block" : "none" }}>
                            <Text style={{ marginBottom: '10px' }}>Ingresa el código</Text>
                            <Input type="text" placeholder={"Código 6 dgts"}
                                onChange={(e) => { setotp(e.target.value) }}></Input>
                            <br /><br />
                            <Stack>
                                {(remainingTime === 0) ? <Link onClick={() => {
                                    setshow(false);
                                }}>No recibí el código</Link> : <Text>Reenviar código en {remainingTime}</Text>}
                                <Button onClick={ValidateOtp}>Ingresar</Button>
                            </Stack>
                        </div>
                    </center>
                </div>
            </Stack>
        </Box>

    );
};

export default LoginPage;
